import { useParams } from "react-router-dom";
import SpecificBlogs from "./SpecificBlogs";
import SpecificBlog2 from "./SpecificBlog2";
import SpecificBlog3 from "./SpecificBlog3";
import SpecificBlog4 from "./SpecificBlog4";
import SpecificBlog5 from "./SpecificBlog5";
import SpecificBlog6 from "./SpecificBlog6";

const BlogToDisplay = () => {
  const { type, id } = useParams();

  if (type === "pr" && id === "best-press-release-distribution-services") {
    return <SpecificBlog3 />;
  } else if (
    type === "pr" &&
    id ===
      "top-benefits-of-using-an-aggregator-for-online-press-release-distribution"
  ) {
    return <SpecificBlog4 />;
  } else if (type === "pr") {
    return <SpecificBlog2 />;
  } else if (type === "bc") {
    return <SpecificBlog5 />;
  } else if (type === "business-service") {
    return <SpecificBlog6 />;
  } else {
    return <SpecificBlogs />;
  }
};

export default BlogToDisplay;
